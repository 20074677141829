@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

.App {
  min-height: 100vh;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (prefers-reduced-motion: no-preference) {
  .fadein {
    animation: App-fadeover 1s 1 ease-in;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}


.App-header {
  background-color: rgba(255,255,255,0.5);
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  bottom: 0;
}

.App-link {
  color: #61dafb;
}

.Emote {
    font-family: 'Josefin Sans', cursive;
    font-size: 4vw;
    border-radius: 2rem;
    padding: 1rem;
    position: absolute;
    background-color: rgba(255,255,255,0.5);
}

.flag {
    height: 1rem;
    width: 2rem;
    padding-right: 1rem;
}

@keyframes App-fadeover {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}